import axios, { AxiosResponse } from "axios";
import instance from "../http";
import { PatientRoutes, RecordsRoutes } from "../http/api";
import type { IConsultationResponse } from "../models/chatTypes";
import type { DoctorDetailResponse } from "../models/consultationsTypes";
import type { IPaymentData, PaymentResponse } from "../models/paymentTypes";
import type { IRecipeResponse } from "../models/recipeTypes";

export default class PaymentService {
    static async getConsultationDetails(
        consultationId: string
    ): Promise<AxiosResponse<IConsultationResponse>> {
        return instance.get(`${RecordsRoutes.RECORDS}/${consultationId}`);
    }

    static async getDoctorInfo(doctorId: string): Promise<AxiosResponse<DoctorDetailResponse>> {
        return instance.get(`${PatientRoutes.DOCTOR_DETAILS}/${doctorId}`);
    }

    static async getIPAddress(): Promise<AxiosResponse<{ ip: string }>> {
        return axios.get("https://api.ipify.org/?format=json");
    }

    static async getPrescription(prescriptionId: string): Promise<AxiosResponse<IRecipeResponse>> {
        return instance.get(`${RecordsRoutes.RECIPE_REQUEST}/${prescriptionId}`);
    }

    static async postPayment(payload: IPaymentData): Promise<AxiosResponse<PaymentResponse>> {
        return instance.post(PatientRoutes.PAYMENT, payload);
    }
}
