import { FC } from "react";
import { Wrapper } from "./styles";

interface Props {
    number: string | number;
    isActive?: boolean;
}

export const CardNumber: FC<Props> = ({ number, isActive }) => {
    return <Wrapper isActive={isActive}>{number}</Wrapper>;
};
