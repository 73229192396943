import { useNavigate, useParams } from "react-router-dom";
import { CardContainer, PaymentPageContainer } from "./styles";
import { useEffect, useState } from "react";
import { Card } from "../../components/payment/card/Card";
import { Form } from "../../components/payment/form/Form";
import {
    FormDataType,
    IPaymentData,
    IPaymentInfo,
    IPromoResponse,
    PaymentsTypesEnum
} from "../../models/paymentTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { PaymentInfo } from "../../components/payment/info/PaymentInfo";
import {
    getConsultationPaymentData,
    getPrescriptionPaymentData,
    getSubscriptionPaymentData
} from "../../store/actions/paymentActions";
import type { DoctorDetailResponse } from "../../models/consultationsTypes";
import { Loader } from "../../components/common/Loader";
import PaymentService from "../../services/PaymentService";
import { showNotification } from "../../helpers/showNotification";
import { useTranslation } from "react-i18next";
import { patientSlice } from "../../store/reducers/PatientSlice";

export const defaultPaymentInfo = {
    ip: "",
    price: 0,
    doctor: {} as DoctorDetailResponse,
    description: "",
    callbackUrl: ""
};

const actions = {
    [PaymentsTypesEnum.CONSULTATION]: getConsultationPaymentData,
    [PaymentsTypesEnum.PRESCRIPTION]: getPrescriptionPaymentData,
    [PaymentsTypesEnum.SEASON]: getSubscriptionPaymentData
};

const OrderPayment = ({ type }: { type: PaymentsTypesEnum }) => {
    const { user } = useAppSelector((state) => state.userReducer);
    const { doctorId, orderId } = useParams();
    const [{ ip, price, description, callbackUrl }, setPaymentData] =
        useState<IPaymentInfo>(defaultPaymentInfo);
    const [loading, setLoading] = useState<boolean>(true);
    const [formData, setFormData] = useState<FormDataType>({});
    const [cardFlipped, setCardFlipped] = useState<boolean>(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const host = window.location.origin;

    const getPaymentData = async () => {
        try {
            const data = await actions[type](host, orderId, doctorId);
            setPaymentData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPaymentData();
    }, []);

    const handleSubmitForm = async () => {
        if (
            !orderId ||
            !formData.card_number ||
            !formData.expires ||
            !formData.card_cvv2 ||
            !formData.payer_phone ||
            !formData.payer_email
        )
            return;
        try {
            const payload: IPaymentData = {
                action: "SALE",
                order_id: orderId,
                // TODO: return this line after testing
                // order_amount: Number(`${price}`).toFixed(2),
                order_amount: Number(`${1}`).toFixed(2),
                order_currency: "UAH",
                order_description: description,
                payer_ip: ip,
                term_url_3ds: callbackUrl,
                card_number: parseInt(formData.card_number.replace(/\s+/g, ""), 10),
                card_exp_month: +formData.expires.split("/")[0],
                card_exp_year: +formData.expires.split("/")[1],
                card_cvv2: +formData.card_cvv2,
                payer_email: formData.payer_email,
                payer_phone: +formData.payer_phone,
                req_token: "Y",
                recurring_init: "Y",
                doctor_id: doctorId,
                type
            };
            const { data } = await PaymentService.postPayment(payload);

            if (data.status === "OK") {
                if (type === PaymentsTypesEnum.SEASON) {
                    dispatch(
                        patientSlice.actions.setNewPromoCodes((data.data as IPromoResponse).codes)
                    );
                }

                const path = callbackUrl.split(host)[1];
                navigate(path);
            } else {
                showNotification(t("Payment processing error, please try again later"), "error");
            }
        } catch (error) {
            console.log(error);
            showNotification(t("Payment processing error, please try again later"), "error");
        }
    };

    if (loading) {
        return <Loader width="100%" height="100vh" />;
    }

    return (
        <PaymentPageContainer>
            <PaymentInfo orderId={orderId} price={`${price}`} description={description} />
            <CardContainer>
                <Card formData={formData} cardFlipped={cardFlipped} />
                <Form
                    email={user.email}
                    phone={user.phone}
                    price={Number(`${price}`).toFixed(2)}
                    setCardFlipped={setCardFlipped}
                    setFormData={setFormData}
                    handleSubmitForm={handleSubmitForm}
                />
            </CardContainer>
        </PaymentPageContainer>
    );
};

export default OrderPayment;
