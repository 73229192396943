import { useEffect, useRef, useState } from "react";
import { uid } from "uid";
import { socket } from "../utils/socket";
import { useAppSelector } from "./redux";

const servers = {
    iceServers: [
        {
            urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302"]
        }
    ],
    iceCandidatePoolSize: 10
};

export interface IChatActions {
    remoteStreamState: MediaStream | null;
    localStreamState: MediaStream | null;
    startCall: () => void;
    closeConnection: () => void;
    switchCameraScreenSharing: () => Promise<any>;
    toggleMute: () => void;
    toggleCamera: () => void;
}

export const useChatStream = (
    roomId: string | null,
    {
        onConnectionOpen,
        onConnectionClose,
        onOtherConnectionClose,
        onConnectionDisconnected
    }: {
        onConnectionOpen: () => void;
        onConnectionClose: () => void;
        onOtherConnectionClose: () => void;
        onConnectionDisconnected: () => void;
    }
): IChatActions => {
    const localRef = useRef<HTMLVideoElement>(null);
    const remoteRef = useRef<HTMLVideoElement>(null);
    const peerRef = useRef<RTCPeerConnection | null>(null);
    const otherUser = useRef<string | null>(null);
    const userStream = useRef<MediaStream | null>(null);
    const senders = useRef<RTCRtpSender[]>([]);
    const [screenIsSharing, setScreenIsSharing] = useState(false);
    const [remoteStreamState, setRemoteStreamState] = useState<MediaStream | null>(null);
    const [localStreamState, setLocalStreamState] = useState<MediaStream | null>(new MediaStream());
    const { senderId } = useAppSelector((state) => state.chatReducer);

    useEffect(() => {
        return () => {
            socket.disconnect();
        };
    }, []);

    const startCall = () => {
        if (!roomId) return;
        socket.connect();
        console.log("START CALL--->");

        navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then((stream) => {
                if (localRef.current) {
                    localRef.current.srcObject = stream;
                }
                setLocalStreamState(stream);
                userStream.current = stream;

                socket.emit("join room", roomId);
                socket.on("other user", (userID) => {
                    callUser(userID);
                    otherUser.current = userID;
                });
                socket.on("user joined", (userID) => {
                    console.log("user joined");

                    otherUser.current = userID;
                });
                socket.on("offer", handleReceiveCall);
                socket.on("answer", handleAnswer);
                socket.on("ice-candidate", handleNewICECandidateMsg);
                socket.on("offerer disconnected", handleOffererDisconnected);
                socket.on("offerer leaved", handleOffererLeaved);
                socket.on("answerer disconnected", handleAnswererCloseConnection);
                socket.on("answerer leaved", handleAnswererCloseConnection);
                onConnectionOpen();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const callUser = (userID: string) => {
        console.log('callUser');
        if (userStream.current) {
            peerRef.current = createPeer(userID);
            userStream.current.getTracks().forEach((track) => {
                if (peerRef.current)
                    senders.current.push(
                        peerRef.current?.addTrack(track, userStream.current as MediaStream)
                    );
            });
        }
    };

    const createPeer = (userID?: string) => {
        console.log('createPeer');
        const peer = new RTCPeerConnection(servers);

        peer.onicecandidate = handleICECandidateEvent;
        peer.ontrack = handleTrackEvent;
        peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

        return peer;
    };

    const handleNegotiationNeededEvent = (userID?: string) => {
        console.log('handleNegotiationNeededEvent');
        if (peerRef.current) {
            peerRef.current
                .createOffer()
                .then((offer) => {
                    return peerRef.current?.setLocalDescription(offer);
                })
                .then(() => {
                    const payload = {
                        target: userID || uid(),
                        caller: socket.id || uid(),
                        sdp: peerRef.current?.localDescription
                    };

                    socket.emit("offer", payload);
                })
                .catch((e) => console.log(e));
        }
    };

    const handleReceiveCall = (incoming: any) => {
        peerRef.current = createPeer();
        console.log("handleReceiveCall");

        const desc = new RTCSessionDescription(incoming.sdp);
        if (peerRef.current && peerRef.current.signalingState !== "closed") {
            peerRef.current
                .setRemoteDescription(desc)
                .then(() => {
                    if (userStream.current) {
                        userStream.current.getTracks().forEach((track) => {
                            const existingSender = peerRef.current?.getSenders().find((sender) => {
                                return sender.track === track;
                            });

                            if (existingSender) {
                                // Remove the existing sender
                                peerRef.current?.removeTrack(existingSender);
                            } else {
                                peerRef.current?.addTrack(track, userStream.current as MediaStream);
                            }
                        });
                    }
                })
                .then(() => {
                    return peerRef.current?.createAnswer();
                })
                .then((answer) => {
                    return peerRef.current?.setLocalDescription(answer);
                })
                .then(() => {
                    const payload = {
                        target: incoming.caller,
                        caller: socket.id || uid(),
                        sdp: peerRef.current?.localDescription
                    };
                    socket.emit("answer", payload);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    function handleOffererLeaved() {
        console.log("handleOffererLeaved");

        stopStream();
        startCall();
    }

    function handleOffererDisconnected() {
        console.log("handleOffererDisconnected");
        stopStream();
        onConnectionDisconnected();
        // startCall();
    }

    function handleAnswererCloseConnection() {
        console.log("handleAnswererCloseConnection");
        stopStream();
        onOtherConnectionClose();
    }

    const handleAnswer = (message: any) => {
        const desc = new RTCSessionDescription(message.sdp);
        console.log("handleAnswer");

        if (peerRef.current) {
            peerRef.current.setRemoteDescription(desc).catch((e) => console.log(e));
        }
    };

    const handleICECandidateEvent = (e: RTCPeerConnectionIceEvent) => {
        console.log("handleICECandidateEvent");

        if (e.candidate) {
            const payload = {
                target: otherUser.current,
                candidate: e.candidate
            };

            socket.emit("ice-candidate", payload);
        }
    };

    const handleNewICECandidateMsg = (incoming: any) => {
        console.log('handleNewICECandidateMsg');
        const candidate = new RTCIceCandidate(incoming);

        if (peerRef.current) {
            peerRef.current.addIceCandidate(candidate).catch((e) => console.log(e));
        }
    };

    const handleTrackEvent = (e: RTCTrackEvent) => {
        console.log('handleTrackEvent');
        if (remoteRef.current) {
            remoteRef.current.srcObject = e.streams[0];
        }
        setRemoteStreamState(e.streams[0]);
    };

    // Function to start screen sharing
    const startScreenShare = async () => {
        if (userStream.current) {
            userStream.current.getTracks().forEach((track) => {
                if (track.kind === "video" && track.label && !track.label.includes("screen")) {
                    track.stop(); // Stop only the screen-sharing track
                }
            });
        }
        try {
            const captureOptions = {
                video: {
                    cursor: "always"
                }
            } as MediaStreamConstraints;

            navigator.mediaDevices.getDisplayMedia(captureOptions).then((stream) => {
                const screenTrack = stream.getTracks()[0];
                if (peerRef.current) {
                    const senders = peerRef.current.getSenders();
                    const videoSender = senders.find((sender) => sender.track?.kind === "video");
                    if (videoSender) {
                        videoSender.replaceTrack(screenTrack);
                    }

                    screenTrack.onended = function () {
                        if (senders) {
                            const track = senders.find(
                                (sender) => sender.track && sender.track.kind === "video"
                            );
                            if (track && userStream.current) {
                                track.replaceTrack(userStream.current?.getTracks()[1]);
                            }
                        } else {
                            screenTrack.stop();
                        }
                    };
                }
            });
        } catch (error) {
            console.error("Error starting screen share:", error);
        }
    };

    // Function to stop screen sharing
    const startCamera = async () => {
        try {
            if (userStream.current) {
                userStream.current.getTracks().forEach((track) => {
                    if (track.kind === "video" && track.label && track.label.includes("screen")) {
                        track.stop(); // Stop only the screen-sharing track
                    }
                });
            }

            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });

            if (peerRef.current) {
                stream.getTracks().forEach((track) => {
                    peerRef.current?.addTrack(track, stream);
                });
            }

            if (localRef.current) {
                localRef.current.srcObject = stream;
            }
            setLocalStreamState(stream);
            userStream.current = stream;

            const videoTrack = stream?.getTracks().find((track) => track.kind === "video");

            if (peerRef.current && videoTrack) {
                const senders = peerRef.current.getSenders();

                senders
                    .find((sender) => sender.track?.kind === "video")
                    ?.replaceTrack(videoTrack)
                    .catch((err) => console.error(err));
            }
        } catch (error) {
            console.error("Error starting screen share:", error);
        }
    };

    const switchCameraScreenSharing = async () => {
        stopStream();
        if (screenIsSharing) {
            await startCamera();
        } else {
            await startScreenShare();
        }
        setScreenIsSharing(!screenIsSharing);
    };

    // Mutes the local's outgoing audio
    const toggleMute = () => {
        const audioTracks = userStream.current?.getAudioTracks();
        if (audioTracks) {
            audioTracks.forEach((track) => {
                track.enabled = !track.enabled;
            });
        }
    };

    const toggleCamera = () => {
        userStream.current?.getTracks().forEach((track) => {
            if (track.kind === "video") {
                track.enabled = !track.enabled;
            }
        });
    };

    const stopStream = () => {
        console.log('stopStream');
        const stream = userStream.current;

        if (stream) {
            const videoTracks = stream.getVideoTracks();

            if (videoTracks[0]) {
                videoTracks[0].enabled = false;
            }
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    };

    const removeRefs = () => {
        console.log('removeRefs');
        if (peerRef.current) {
            const senders = peerRef.current.getSenders();
            senders.forEach((sender) => {
                if (peerRef.current && peerRef.current.signalingState !== "closed") {
                    peerRef.current.removeTrack(sender);
                }
            });
            peerRef.current.close();
        }
        if (localRef.current) {
            localRef.current.srcObject = null;
        }
        userStream.current = null;
        setLocalStreamState(null);
        setRemoteStreamState(null);
    };

    const closeConnection = () => {
        socket.emit("leave room", roomId);
        stopStream();
        removeRefs();
        onConnectionClose();
        socket.emit("leave room", roomId);
        socket.emit("disconnect-user", { userId: senderId });
        socket.off("offerer disconnected");
        socket.disconnect();
    };

    return {
        startCall,
        closeConnection,
        switchCameraScreenSharing,
        toggleMute,
        toggleCamera,
        remoteStreamState,
        localStreamState
    };
};
