import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultHealthForm } from "../../components/profile/constants";
import type {
    IAddress,
    IDassForm,
    IHealthForm,
    IMedicalCard,
    IPatientState,
    IPerson,
    IPolicy,
    IPromoCode
} from "../../models/patientTypes";
import type { IMedicalHistory, ITherapyResponse } from "../../models/recordsTypes";

const initialState: IPatientState = {
    persons: [],
    policies: [],
    addresses: [],
    healthForm: defaultHealthForm,
    dassForm: [],
    isLoading: false,
    error: "",
    medicalCard: [],
    medicalHistory: [],
    therapy: [],
    promoCodes: [],
    newPromoCodes: []
};

export const patientSlice = createSlice({
    name: "patient",
    initialState,
    reducers: {
        setPersons(state, action: PayloadAction<IPerson[]>) {
            state.isLoading = false;
            state.error = "";
            state.persons = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setPolicies(state, action: PayloadAction<IPolicy[]>) {
            state.isLoading = false;
            state.error = "";
            state.policies = action.payload;
        },
        setAddresses(state, action: PayloadAction<IAddress[]>) {
            state.isLoading = false;
            state.error = "";
            state.addresses = action.payload;
        },
        setHealthForm(state, action: PayloadAction<IHealthForm>) {
            state.isLoading = false;
            state.error = "";
            state.healthForm = action.payload;
        },
        setDassForm(state, action: PayloadAction<Array<IDassForm>>) {
            state.isLoading = false;
            state.error = "";
            state.dassForm = action.payload;
        },
        setMedicalCard(state, action: PayloadAction<Array<IMedicalCard>>) {
            state.isLoading = false;
            state.error = "";
            state.medicalCard = action.payload;
        },
        setMedicalHistory(state, action: PayloadAction<IMedicalHistory[]>) {
            state.error = "";
            state.isLoading = false;
            state.medicalHistory = action.payload;
        },
        updateMedicalHistory(state, action: PayloadAction<IMedicalHistory>) {
            const filteredHistory = state.medicalHistory.filter(
                (consultation) => consultation.id !== action.payload.id
            );
            state.medicalHistory = [...filteredHistory, action.payload];
        },
        setTherapy(state, action: PayloadAction<ITherapyResponse[]>) {
            state.error = "";
            state.therapy = action.payload;
        },
        setPromoCodes(state, action: PayloadAction<IPromoCode[]>) {
            state.isLoading = false;
            state.promoCodes = action.payload;
        },
        setNewPromoCodes(state, action: PayloadAction<string[]>) {
            state.newPromoCodes = action.payload;
        }
    }
});

export default patientSlice.reducer;
