import { FC } from "react";
import type { FormDataType } from "../../../models/paymentTypes";
import { getPayService } from "../../../utils/getPayService";
import { CardExpire } from "../card-expire/CardExpire";
import { CardNumber } from "../card-number/CardNumber";
import { CardCVV } from "../cardCVV/CardCVV";
import { CardPayService } from "../cardpayservice/CardPayService";
import { Wrapper, FrontSide, BackSide, BlackLine } from "./styles";

interface Props {
    formData: FormDataType;
    cardFlipped: boolean;
}

export const Card: FC<Props> = ({ formData, cardFlipped }) => {
    const {
        card_number = "**** **** **** ****",
        expires = "MM/YYYY",
        card_cvv2 = "***"
    } = formData;

    return (
        <Wrapper>
            <FrontSide isFlipped={cardFlipped}>
                <CardPayService service={getPayService(card_number)} />
                <CardNumber number={card_number} />
                <CardExpire expires={expires} />
            </FrontSide>
            <BackSide isFlipped={cardFlipped}>
                <BlackLine />
                <CardCVV CVV={card_cvv2} />
            </BackSide>
        </Wrapper>
    );
};
