import { Wrapper, Title, Name } from "./styles";

export const CardExpire = ({ expires }: { expires: string }) => {
    return (
        <Wrapper>
            <Title>Expires</Title>
            <Name>{expires}</Name>
        </Wrapper>
    );
};
