import styled from "styled-components";
import InputMask from "react-input-mask";

export const Wrapper = styled.form`
    position: relative;
    margin: -180px auto;
    width: 100%;
    height: inherit;
    max-width: 570px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;
    gap: 15px;

    @media (max-width: 790px) {
        margin: 0;
        padding: 25px;
    }
`;

export const Button = styled.button<{ gridArea: string }>`
    grid-area: button;
    width: 100%;
    height: 55px;
    background-image: linear-gradient(to right bottom, #00f24f, #19d740, #20be33, #22a426, #208c1a);
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    cursor: pointer;
    grid-area: ${(props) => props.gridArea};
`;

export const Input = styled(InputMask)<{
    $iserror?: boolean;
}>`
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: ${({ $iserror }) => ($iserror ? "1px solid #fd6060" : "1px solid #ced6e0")};
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: ${({ $iserror }) => ($iserror ? "#fd6060" : "#1a3b5d")};
    font-family: "Source Sans Pro", sans-serif;
    box-sizing: border-box;

    &:disabled {
        background: #e5e5e5;
        cursor: not-allowed;
    }
`;

export const InputWrapper = styled.div<{ gridArea: string }>`
    grid-area: ${(props) => props.gridArea};

    .error-state {
        font-size: 12px;
        color: #fd6060;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    margin-bottom: 5px;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
`;
