import { t } from "i18next";

export const getLanguagesOptions = () => [
    { label: t("Ukrainian"), value: t("Ukrainian") },
    { label: t("English"), value: t("English") },
    { label: t("Polish"), value: t("Polish") },
    { label: t("German"), value: t("German") }
];

export const countries = () => [
    { label: t("Ukraine"), value: "Україна" },
    { label: t("Poland"), value: "Польша" },
    { label: t("Lithuania"), value: "Литва" },
    { label: t("Latvia"), value: "Латвія" }
];

export const defaultHealthForm = {
    sex: "",
    bePregnancy: false,
    pregnancyCount: 0,
    childrensCount: 0,
    abortion: false,
    abortionCount: 0,
    canceledPregnance: 0,
    smoking: "",
    alcohol: null,
    alcoholDescription: "",
    drugs: "",
    familyStatus: "",
    workStatus: "",
    lifeStyle: "",
    lifeStyleHours: "",
    arterialHypertension: null,
    parent_hypertension: null,
    mother60: "",
    parentsArterialHypertension: "",
    salt: null,
    saltLove: null,
    sleepHours: "",
    stressLevel: 0,
    painLevel: 0,
    anxietyLevel: 0
};
