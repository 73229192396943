import styled from "styled-components";

export const PaymentPageContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    @media (max-width: 790px) {
        flex-direction: column;
    }
`;

export const PaymentInformationContainer = styled.div`
    padding: 40px 25px;
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 90vh;
    gap: 10px;
    font-family: "Roboto";
    position: fixed;

    .payer_info {
        display: flex;
        flex-direction: column;

        .logo {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        .payment_info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 20px 0;
            font-family: "Roboto";
            max-width: 300px;

            span {
                color: #909090;
                font-size: 18px;
            }

            .order,
            .name {
                font-size: 22px;
                margin-top: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                color: #000;
            }

            .price {
                font-size: 42px;
                font-weight: 500;
                margin-top: 15px;

                span {
                    font-size: 25px;
                }
            }
        }
    }

    .payment_methods {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    @media (max-width: 790px) {
        position: relative;
        width: 92%;
        height: auto;
        padding-bottom: 0;

        .payer_info {
            .payment_info {
                max-width: 100%;
            }
        }
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: calc(100vh + 140px);
    flex-direction: column;
    align-items: center;
    font-family: "Roboto";
    border-left: 2px solid #ebebeb;
    margin-left: 350px;

    @media (max-width: 790px) {
        margin-left: 0;
        border: none;
        height: auto;
    }
`;
