import {
    CalendarOutlined,
    FileSearchOutlined,
    FileTextOutlined,
    LaptopOutlined,
    MessageOutlined,
    UserOutlined,
    WalletOutlined
} from "@ant-design/icons";
import { Badge, List } from "antd";
import clsx from "clsx";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";

interface Props {
    toggleNavbarMenu: () => void;
}

export const MobileNavLink: FC<Props> = ({ toggleNavbarMenu }) => {
    const [selectedLink, setSelectedLink] = useState<string[]>([]);
    const { user } = useAppSelector((state) => state.userReducer);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const count = 0;

    useEffect(() => {
        const pathNames = pathname.split("/");
        setSelectedLink([pathNames[pathNames.length - 1]]);
    }, [pathname]);

    const navLinks = [
        {
            key: "appointments",
            icon: <CalendarOutlined />,
            label: t("Records")
        },
        {
            key: "recipes",
            icon: <FileTextOutlined />,
            label: t("Recipes")
        },
        {
            key: user.role === UserRole.DOCTOR ? "patients" : "disease",
            icon: <FileSearchOutlined />,
            label: user.role === UserRole.DOCTOR ? t("Patients") : t("Medical history")
        },
        {
            key: "finance",
            icon: <WalletOutlined />,
            label: t("Finance")
        },
        {
            key: "chat",
            icon: <MessageOutlined />,
            label: (
                <ChatMenuItem>
                    {t("Chat")} {count > 0 && <Badge count={count} color="rgba(24, 144, 255, 1)" />}
                </ChatMenuItem>
            )
        },
        {
            key: "consultation",
            icon: <LaptopOutlined />,
            label: user.role === UserRole.DOCTOR ? t("Work schedule") : t("Online consultations")
        },
        {
            key: "profile",
            icon: <UserOutlined />,
            label: t("Profile")
        }
    ];

    const onSelectMenuItem = (key: string) => () => {
        navigate(`/${user.role}/${key}`);
        toggleNavbarMenu();
    };

    return (
        <List
            itemLayout="horizontal"
            dataSource={navLinks}
            split={true}
            renderItem={(item) => (
                <CustomNavbarLink
                    className={clsx("ant-list-item", selectedLink.includes(item.key) && "active")}
                    onClick={onSelectMenuItem(item.key)}
                >
                    <List.Item.Meta avatar={item.icon} title={<span>{item.label}</span>} />
                </CustomNavbarLink>
            )}
        />
    );
};

const ChatMenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const CustomNavbarLink = styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    color: rgba(0, 0, 0, 0.88);
    transition: all 0.2s ease-in-out;

    &:hover * {
        color: #58cdf8;
        transition: all 0.2s ease-in-out;
    }
`;
