import { FC } from "react";
import { useTransition, animated } from "react-spring";

import { Wrapper } from "./styles";

const services = {
    mastercard: <img width={140} src="/images/master-card-logo.svg" alt="master-card-logo" />,
    visa: <img width={90} height={30} src="/images/visa-logo.png" alt="visa-logo" />
};

const AnimatedWrapper = animated(Wrapper);

interface Props {
    service: "visa" | "mastercard";
}

export const CardPayService: FC<Props> = ({ service }) => {
    const transitions = useTransition(service, {
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
        leave: { transform: "translate3d(0,40px,0)", opacity: 0 }
    });
    return (
        <>
            {transitions((props, item) => (
                <AnimatedWrapper style={props}>
                    {services[item as keyof typeof services]}
                </AnimatedWrapper>
            ))}
        </>
    );
};
