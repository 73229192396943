import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AuthState, ILoginState, IRegisterState, IResetState } from "../../models/authTypes";
import { UserRole } from "../../models/authTypes";

const initialState: AuthState = {
    role: UserRole.PATIENT,
    error: "",
    isLoading: false,
    login: {
        email: "",
        password: "",
        savedInSystem: true
    },
    registration: {
        email: "",
        location_country: "",
        terms: false,
        personalData: false,
        code: "",
        password: "",
        repeated_password: "",
        savedInSystem: true
    },
    reset: {
        role: UserRole.PATIENT,
        email: "",
        code: "",
        password: "",
        repeated_password: ""
    }
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        changeUserRole(state, action: PayloadAction<UserRole>) {
            state.role = action.payload;
            state.isLoading = false;
            state.error = "";
            state.login = {
                email: "",
                password: "",
                savedInSystem: true
            };
            state.registration = {
                email: "",
                location_country: "",
                terms: false,
                code: "",
                personalData: false,
                password: "",
                repeated_password: "",
                savedInSystem: true
            };
            state.reset = {
                role: action.payload,
                email: "",
                code: "",
                password: "",
                repeated_password: ""
            };
        },
        updateLoginForm(state, action: PayloadAction<ILoginState>) {
            state.login = action.payload;
            state.error = "";
        },
        setErrorMessage(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.isLoading = false;
        },
        updateRegisterForm(state, action: PayloadAction<IRegisterState>) {
            state.registration = action.payload;
            state.error = "";
        },
        updateResetPasswordForm(state, action: PayloadAction<IResetState>) {
            state.reset = action.payload;
            state.error = "";
        },
        setLoadingStatus(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setOtpCodeSuccess(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.registration.code = action.payload;
            state.reset.code = action.payload;
        },
        verifyCodeSuccess(state, action: PayloadAction<UserRole>) {
            state.isLoading = false;
            state.role = action.payload;
        },
        setUserRole(state, action: PayloadAction<UserRole>) {
            state.role = action.payload;
        }
    }
});

export default authSlice.reducer;
