import { useFormik } from "formik";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { validationCardFields } from "../../../helpers/cardValidation";
import type { FormDataType } from "../../../models/paymentTypes";
import { Wrapper, Button, Input, InputWrapper, Label } from "./styles";

const cardNumberMask = "9999 9999 9999 9999";

interface Props {
    email: string | null;
    phone: string | null;
    price: string;
    handleSubmitForm: () => Promise<void>;
    setFormData: Dispatch<SetStateAction<FormDataType>>;
    setCardFlipped: Dispatch<SetStateAction<boolean>>;
}

export const Form: FC<Props> = ({
    setFormData,
    setCardFlipped,
    email,
    phone,
    price,
    handleSubmitForm
}) => {
    const { t } = useTranslation();
    const form = useFormik({
        initialValues: {
            card_number: "",
            expires: "",
            card_cvv2: "",
            payer_email: email || "",
            payer_phone: phone || ""
        },
        onSubmit: async (values) => {
            const errors = validationCardFields(values);

            if (Object.keys(errors).length) {
                form.setErrors(errors);
                return;
            }

            await handleSubmitForm();
        },
        validate: (values) => {
            const validatedFormData = (Object.keys(values) as (keyof typeof values)[]).reduce(
                (acc, el) => {
                    if (values[el] === "") {
                        return acc;
                    } else {
                        return { ...acc, [el]: values[el] };
                    }
                },
                {}
            );
            setFormData(validatedFormData);
        }
    });

    const validateCardNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.replace(/#/g, "").length < 19) {
            form.setFieldValue("card_number", "");
        }
    };

    const flipCard = () => {
        setCardFlipped((prev) => !prev);
    };

    return (
        <Wrapper onSubmit={form.handleSubmit}>
            <InputWrapper gridArea="number">
                <Label htmlFor="card_number">Card Number</Label>
                <Input
                    id="card_number"
                    name="card_number"
                    type="text"
                    $iserror={!!form.errors["card_number"]}
                    onChange={form.handleChange}
                    value={form.values.card_number}
                    mask={cardNumberMask}
                    maskChar={"*"}
                    onBlur={validateCardNumber}
                    placeholder={"**** **** **** ****"}
                />
                {!!form.errors["card_number"] && (
                    <span className="error-state">{form.errors["card_number"]}</span>
                )}
            </InputWrapper>
            <InputWrapper gridArea="expires">
                <Label htmlFor="expires">Expiration Date</Label>
                <Input
                    id="expires"
                    name="expires"
                    type="text"
                    $iserror={!!form.errors["expires"]}
                    onChange={form.handleChange}
                    value={form.values.expires}
                    mask={"99/9999"}
                    placeholder={"MM/YYYY"}
                />
                {!!form.errors["expires"] && (
                    <span className="error-state">{form.errors["expires"]}</span>
                )}
            </InputWrapper>
            <InputWrapper gridArea="cvv">
                <Label htmlFor="card_cvv2">CVV</Label>
                <Input
                    id="card_cvv2"
                    name="card_cvv2"
                    type="text"
                    onChange={form.handleChange}
                    value={form.values.card_cvv2}
                    onFocus={flipCard}
                    onBlur={flipCard}
                    $iserror={!!form.errors["card_cvv2"]}
                    mask={"999"}
                    maskChar={"*"}
                    placeholder={"***"}
                />
                {!!form.errors["card_cvv2"] && (
                    <span className="error-state">{form.errors["card_cvv2"]}</span>
                )}
            </InputWrapper>
            <InputWrapper gridArea="payer_email">
                <Label htmlFor="payer_email">Email</Label>
                <Input
                    id="payer_email"
                    name="payer_email"
                    type="email"
                    $iserror={!email && !!form.errors["payer_email"]}
                    disabled={!!email}
                    onChange={form.handleChange}
                    value={form.values.payer_email}
                    mask=""
                />
                {!email && !!form.errors["payer_email"] && (
                    <span className="error-state">{form.errors["payer_email"]}</span>
                )}
            </InputWrapper>
            <InputWrapper gridArea="payer_phone">
                <Label htmlFor="payer_phone">Phone</Label>
                <Input
                    id="payer_phone"
                    name="payer_phone"
                    type="text"
                    onChange={form.handleChange}
                    value={form.values.payer_phone}
                    disabled={!!phone}
                    $iserror={!phone && !!form.errors["payer_phone"]}
                    mask={"999999999999"}
                    maskChar={"#"}
                    placeholder={"##########"}
                />
                {!phone && !!form.errors["payer_phone"] && (
                    <span className="error-state">{form.errors["payer_phone"]}</span>
                )}
            </InputWrapper>
            <Button gridArea="button" type="submit">
                Сплатити {price} {t("UAH")}
            </Button>
        </Wrapper>
    );
};
