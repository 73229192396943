import { Grid, Tooltip } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PaymentInformationContainer } from "../../../pages/payment/styles";

interface Props {
    orderId?: string;
    price: string;
    description: string;
}

export const PaymentInfo: FC<Props> = ({ orderId, price, description }) => {
    const { t } = useTranslation();
    const { md } = Grid.useBreakpoint();
    const host = window.location.origin;

    return (
        <PaymentInformationContainer>
            <div className="payer_info">
                <div className="logo">
                    <img width={170} src="/images/platon-logo-gor.png" alt="platon-logo" />
                    {!md && (
                        <div className="payment_methods">
                            <img
                                width={60}
                                src="/images/visa_placeholder.png"
                                alt="visa_placeholder"
                            />
                            <img width={60} src="/images/mc_placeholder.svg" alt="mc_placeholder" />
                        </div>
                    )}
                </div>
                <div className="payment_info">
                    <span className="name">Virtual Medical Space</span>
                    <span>{host}</span>
                    {orderId && (
                        <Tooltip placement="bottom" title={orderId} arrow={{ pointAtCenter: true }}>
                            <span className="order">
                                Замовлення <br />№ {orderId}
                            </span>
                        </Tooltip>
                    )}
                    <span>
                        Призначення платежу:
                        <br />
                        {description}
                    </span>
                    <p className="price">
                        {Number(price).toFixed(2)} <span>{t("UAH")}</span>
                    </p>
                </div>
            </div>
            {md && (
                <div className="payment_methods">
                    <img width={60} src="/images/visa_placeholder.png" alt="visa_placeholder" />
                    <img width={60} src="/images/mc_placeholder.svg" alt="mc_placeholder" />
                </div>
            )}
        </PaymentInformationContainer>
    );
};
