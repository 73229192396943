import { FC, Fragment, MouseEvent, ReactNode, useState } from "react";
import CButton, { CustomButtonProps } from "./CButton";
import CryptoJS from "crypto-js";
import utf8 from "utf8";
import type { IRecipeRequest } from "../../models/recipeTypes";
import type { ISuccessConsultation } from "../../models/consultationsTypes";
import { useNavigate } from "react-router-dom";

const pass = process.env.REACT_APP_PLATON_PASS ?? "";
const key = process.env.REACT_APP_PLATON_KEY ?? "";
const PLATON_API = process.env.REACT_APP_PLATON_API ?? "";
const payment = "CC";
const req_token = "Y";
const currency = "UAH";

interface Props extends CustomButtonProps {
    description: string;
    callbackUrl: string;
    email: string;
    children: ReactNode;
    createOrder: () => Promise<ISuccessConsultation | IRecipeRequest | void>;
    price?: number;
    callbackErrorUrl?: string;
}

const PaymentButton: FC<Props> = ({
    price,
    description,
    callbackUrl,
    callbackErrorUrl,
    children,
    createOrder,
    email,
    ...props
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const host = window.location.origin;

    const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!price) return;

        try {
            // create new order
            setLoading(true);
            const order = await createOrder();
            if (!order) return;

            const orderId = order.id;
            // check promo code for free consultation
            if (!!(order as ISuccessConsultation).promo) {
                navigate(`${callbackUrl}?order=${orderId}`);
                return;
            }

            // generate and submit payment form
            const data = btoa(
                JSON.stringify({
                    amount: Number(price).toFixed(2),
                    description: utf8.encode(description),
                    currency
                })
            );
            const url = `${host}${callbackUrl}`;

            const sign = CryptoJS.MD5(
                key.split("").reverse().join("").toUpperCase() +
                    payment.split("").reverse().join("").toUpperCase() +
                    data.split("").reverse().join("").toUpperCase() +
                    url.split("").reverse().join("").toUpperCase() +
                    pass.split("").reverse().join("").toUpperCase()
            ).toString();

            const form = document.getElementById("paymentForm") as HTMLFormElement;

            if (form) {
                (document.getElementById("payment") as HTMLInputElement).value = payment;
                (document.getElementById("key") as HTMLInputElement).value = key;
                (document.getElementById("url") as HTMLInputElement).value = url;
                (document.getElementById("data") as HTMLInputElement).value = data;
                (document.getElementById("req_token") as HTMLInputElement).value = req_token;
                (document.getElementById("sign") as HTMLInputElement).value = sign;
                (document.getElementById("order") as HTMLInputElement).value = orderId;
                (document.getElementById("email") as HTMLInputElement).value = email;

                form.submit();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <form id="paymentForm" action={PLATON_API} method="post" style={{ display: "none" }}>
                <input type="hidden" name="payment" id="payment" />
                <input type="hidden" name="key" id="key" />
                <input type="hidden" name="url" id="url" />
                <input type="hidden" name="data" id="data" />
                <input type="hidden" name="req_token" id="req_token" />
                <input type="hidden" name="sign" id="sign" />
                <input type="hidden" name="email" id="email" />
                <input type="hidden" name="order" id="order" />
            </form>
            <CButton
                {...props}
                loading={props.loading || loading}
                disabled={props.disabled || loading}
                onClick={handleSubmit}
            >
                {children}
            </CButton>
        </Fragment>
    );
};

export default PaymentButton;
